function App() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1>Stickies Cloud</h1>
      <h3>Landing page coming soon!</h3>
    </div>
  )
}

export default App
